import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="parts"
export default class extends Controller {
  connect() {
  }

  showNewPartDialog(e) {
    e.preventDefault()
    document.getElementById('dialog-form').classList.remove('hidden-by-css')
  }

  hideNewPartDialog(e) {
    e.preventDefault()
    document.getElementById('dialog-form').classList.add('hidden-by-css')
  }
}

import { Controller } from "@hotwired/stimulus"
import { get } from '@rails/request.js'

// Connects to data-controller="service-visits--form"
export default class extends Controller {

    updateLocationOptions(e) {
        get(e.target.dataset.updatePath, {
            query: {service_visit_owner_id: e.target.value},
            responseKind: 'script'
        })
    }
}

import { FetchRequest } from "@rails/request.js";
let activeCustomMarker = undefined;

const createCustomMapMarker = function(...args) {

  const obj = args[0],
    val = obj.OverlayView,
    OverlayView = val != null ? val : google.maps.OverlayView,
    {
      location,
      map,
      isMobile
    } = obj;
  let CustomMapMarker = (function () {
    let removeClassesFromInactive = undefined;
    CustomMapMarker = class CustomMapMarker extends OverlayView {
      static initClass() {

        this.prototype.MAP_STYLES = {
          'eLocker': 'locker-pin',
          'Group Parking': 'group-pin',
          'Bike Hangar': 'hangar-pin',
          'Vendor': 'vendor-pin'
        };
        this.prototype.PROBLEM_STYLES = {
          'Work Order': 'work-order-pin',
          'Problem report': 'problem-report-pin',
          'Both problems': 'both-problem-pin'
        };

        removeClassesFromInactive = function (elem) {
          elem.className = elem.className.replace(/[a-z]{1,}-pin-active/, '');
          elem.classList.remove('active-pin');
          elem.classList.remove('previously-clicked');
          return elem;
        };
      }

      constructor(location, map, isMobile) {
        super();
        this.latlng = new google.maps.LatLng(location.latitude, location.longitude);
        this.location = location;
        this.map = map;
        this.isMobile = isMobile;
        this.setMap(map);
      }

      createDiv() {
        this.div = document.createElement('div');

        if (this.location.type === 'friendly_type') {
          this.div.className = 'map-marker ' + this.MAP_STYLES[this.location.location_friendly_type];
        } else {
          const count_problems = this.location.problem_reports.length + this.location.work_orders.length;
          if (count_problems > 9) {
            this.div.innerHTML = '9+';
          } else {
            this.div.innerHTML = count_problems;
          }
          if ((this.location.problem_reports.length > 0) && (this.location.work_orders.length > 0)) {
            this.div.className = 'map-marker count-pin ' + this.PROBLEM_STYLES['Both problems'];
          } else {
            if (this.location.problem_reports.length > 0) {
              this.div.className = 'map-marker count-pin ' + this.PROBLEM_STYLES['Problem report'];
            } else {
              this.div.className = 'map-marker count-pin ' + this.PROBLEM_STYLES['Work Order'];
            }
          }
        }

        if (!this.isMobile) {
          return this.addDesktopListener();
        }
        if (DeviceResolver.isTablet()) {
          return this.addTabletListener();
        }
        return this.addMobileListener();
      }

      addDesktopListener() {
        const self = this;
        this.div.addEventListener('mouseleave', function (event) {
          if (activeCustomMarker) {
            activeCustomMarker.deactivate();
          }
          return self.deactivate();
        });
        this.div.addEventListener('mouseenter', function (event) {
          if (activeCustomMarker) {
            activeCustomMarker.deactivate();
          }
          return self.activate();
        });
        this.div.addEventListener('click', function (event) {
          self.showLocationDetail();
          return self.clickActivate();
        });
      }

      addTabletListener() {
        const self = this;
        this.div.addEventListener('click', function (event) {
          if (activeCustomMarker) {
            activeCustomMarker.deactivate();
          }
          self.showLocationDetail();
          return self.activate();
        })
      }

      addMobileListener() {
        const self = this;
        this.div.addEventListener('click', function (event) {
          if (activeCustomMarker) {
            activeCustomMarker.deactivate();
          }
          map.setCenter(self.getPosition());
          return self.activate();
        })
      }

      appendDivToOverlay() {
        return this.getPanes().overlayImage.appendChild(this.div);
      }

      positionDiv() {
        const point = this.getProjection().fromLatLngToDivPixel(this.latlng);
        if (point) {
          // anchor is middle bottom
          this.div.style.left = (point.x - (this.div.offsetWidth / 2)) + 'px';
          return this.div.style.top = (point.y - this.div.offsetHeight) + 'px';
        }
      }

      draw() {
        if (!this.div) {
          this.createDiv();
          this.appendDivToOverlay();
        }
        return this.positionDiv();
      }

      getPosition() {
        return this.latlng;
      }

      addMapPinStyle() {
        if (this.location.type === 'friendly_type') {
          return this.div.classList.add(this.MAP_STYLES[this.location.location_friendly_type] + '-active');
        } else {
          this.div.classList.add('new-active-pin');
          if ((this.location.problem_reports.length > 0) && (this.location.work_orders.length > 0)) {
            return this.div.classList.add(this.PROBLEM_STYLES['Both problems'] + '-active');
          } else {
            if (this.location.problem_reports.length > 0) {
              return this.div.classList.add(this.PROBLEM_STYLES['Problem report'] + '-active');
            } else {
              return this.div.classList.add(this.PROBLEM_STYLES['Work Order'] + '-active');
            }
          }
        }
      }

      removeMapPinStyle() {
        if (this.div.classList.contains('click')) {
          return;
        }

        if (this.location.type === 'friendly_type') {
          return this.div.classList.remove(this.MAP_STYLES[this.location.location_friendly_type] + '-active');
        } else {
          this.div.classList.remove('new-active-pin');
          if ((this.location.problem_reports.length > 0) && (this.location.work_orders.length > 0)) {
            return this.div.classList.remove(this.PROBLEM_STYLES['Both problems'] + '-active');
          } else {
            if (this.location.problem_reports.length > 0) {
              return this.div.classList.remove(this.PROBLEM_STYLES['Problem report'] + '-active');
            } else {
              return this.div.classList.remove(this.PROBLEM_STYLES['Work Order'] + '-active');
            }
          }
        }
      }

      clickActivate() {
        if (!this.div) {
          return;
        }
        const self = this;

        this.div.classList.add('click');
        self.addMapPinStyle();
        $('.click').not(this.div).removeClass('click').addClass('previously-clicked');
        $('.previously-clicked').removeClass('active');
        const element = document.getElementsByClassName('previously-clicked')[0];
        if (element) {
          return removeClassesFromInactive(element);
        }
      }

      activate() {
        if (!this.div) {
          return;
        }

        const self = this;
        activeCustomMarker = this;
        this.div.classList.add('active-pin');
        self.addMapPinStyle();
        const active_div = this.div;
        const possibleRemove = () => active_div.classList.remove('new-active-pin');

        setTimeout(possibleRemove, 500);
        const callback = function () {
          if (!DeviceResolver.isTablet()) {
            return self.customBubble = window.createCustomMapBubble({
              marker: self,
              location: self.location,
              map: self.map
            });
          }
        };
        return setTimeout(callback, 100);
      }

      deactivateClick() {
        if (!this.div) {
          return;
        }

        const _content = $(this).next('.click');
        return $('.click').not(_content).removeClass('click');
      }

      deactivate() {
        if (!this.div) {
          return;
        }

        activeCustomMarker = undefined;
        if (!this.div.classList.contains('click')) {
          this.div.classList.remove('active-pin');
        }
        const self = this;
        self.removeMapPinStyle();
        const callback = function () {
          if (self.customBubble) {
            return self.customBubble.remove();
          }
        };
        return setTimeout(callback, 100);
      }

      show() {
        if (!this.div) {
          return;
        }

        this.div.style.display = 'block';
        return this.positionDiv();
      }

      hide() {
        if (!this.div) {
          return;
        }

        if (activeCustomMarker === this) {
          this.deactivate();
        }
        return this.div.style.display = 'none';
      }

      async showLocationDetail() {
        const self = this;
        const request = new FetchRequest('get', `/maps/locations/${this.location.url_id}/sidebar_info`, {
          responseKind: 'script'
        })
        $('.miles-away').hide()
        const response = await request.perform()
        if (response.ok) {
          CustomMapMarker.showDistanceForPosition({
            latitude: self.location.latitude,
            longitude: self.location.longitude
          })
        }
      }

      static showDistanceForPosition(locationPosition) {
        if (!navigator.geolocation) {
          return;
        }

        return navigator.geolocation.getCurrentPosition(function (userPosition) {
          const distance = window.distanceServices.distanceInBetweenGPSCoordinates(locationPosition, userPosition.coords);
          $('#miles-away-number').text(Number(Math.round(distance + 'e' + 1) + 'e-' + 1));
          return $('.miles-away').show();
        });
      }
    };
    CustomMapMarker.initClass();
    return CustomMapMarker;
  })();

  window.CustomMapMarker = CustomMapMarker;

  return new CustomMapMarker(location, map, isMobile);
};

class CustomMapMarkersCollection {

  constructor(locations, map) {
    this.hide = this.hide.bind(this);
    this.show = this.show.bind(this);
    this.activate = this.activate.bind(this);
    this.allMarkers = {};
    this.activeMarkers = {};
    this.isMobile = DeviceResolver.isMobileOrTablet();
    this.activateGlobalListeners();
    this.build(locations, map);
  }

  activateGlobalListeners() {
    if (!this.isMobile) { return; }

    return $(document).on('click', function(event) {
      const target = $(event.target);
      if ((target.closest('.map-bubble').length > 0) || (target.closest('.map-marker').length > 0)) { return; }
      if (activeCustomMarker && !DeviceResolver.isTablet()) { return activeCustomMarker.deactivate(); }
    });
  }

  build(locations, map) {
    const self = this;
    return $.each(locations, function(index, location) {
      const customMarker = createCustomMapMarker({location, map, isMobile: self.isMobile});
      self.allMarkers[location.id] = customMarker;
      return self.activeMarkers[location.id] = customMarker;
    });
  }

  hide(location_id) {
    if (!this.activeMarkers[location_id]) { return; }
    return this.activeMarkers[location_id].hide();
  }

  show(location_id) {
    if (!this.activeMarkers[location_id]) { return; }
    return this.activeMarkers[location_id].show();
  }

  showLocationsInBox(lat0, lng0, lat1, lng1) {
    const self = this;
    return $.each(this.allMarkers, function(locationId, marker) {
      if (window.distanceServices.inBoundingBox(lat0, lng0, lat1, lng1, marker.latlng.lat(), marker.latlng.lng())) {
        self.activeMarkers[locationId] = marker;
        return marker.show();
      } else {
        delete self.activeMarkers[locationId];
        return marker.hide();
      }
    });
  }

  activate(location_id) {
    if (activeCustomMarker) { activeCustomMarker.deactivate(); }
    return this.allMarkers[location_id].activate();
  }
}

window.CustomMapMarkersCollection = CustomMapMarkersCollection;

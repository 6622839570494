createCustomMapBubble = ({ OverlayView = google.maps.OverlayView, marker, location, map }) ->

  bubble_sample = document.querySelector('[data-selector="sample-bubble"]')

  class CustomMapBubble extends OverlayView

    PHOTOS_EXPIRATION: 900000 # ms
    AVAILABILITY_THRESHOLD: 4 # hours

    constructor: (marker, location, map)->
      super()
      @marker = marker
      @latlng = new google.maps.LatLng(location.latitude, location.longitude)
      @location = location
      @map = map
      @shouldBeRemoved = false
      @setMap(map)

    createDiv: ->
      if document.querySelector('[data-selector="sample-bubble"].show-buddle')
        document.querySelector('[data-selector="sample-bubble"].show-buddle').forEach (item) ->
          item.remove()
      @div = bubble_sample.cloneNode(true)
      @div.style.display = 'inline'
      @div.classList.add('show-bubble')
      @div.querySelector(".bubble-text").classList.add(@obtainStyle() + '-border')
      @div.querySelector(".bubble-text h2").innerHTML = @location.human_name
      @div.querySelector(".bubble-text h2").classList.add(@obtainStyle())
      @div.querySelector('.bubble-text form').setAttribute("action", "/maps/locations/#{@location.id}/modal_info") if @div.querySelector('.bubble-text form')
      @div.querySelector(".bubble-text h3").innerHTML = [@location.street_address, @location.city, @location.state + " " + @location.postal_code].join(', ')
      @div.querySelector(".bubble-text .space .sum").innerHTML = @totalNumberSpaces()
      @div.querySelector(".bubble-text .space .online-info .available").innerHTML = @availableSpaces()
      @div.querySelector(".bubble-text .space .online-info .updated").innerHTML = @updatedAt()
      @div.querySelector(".bubble-text .oversized-spaces").innerHTML = @oversizedSpaces()
      @div.querySelector(".bubble-text .free-hours").innerHTML = @freeHours()
      @div.querySelector(".bubble-text .rental-info .price").innerHTML = @rentalPrice()
      @div.querySelector(".bubble-text .rental-info .duration").innerHTML = @rentalDuration()
      @div.querySelector(".bubble-text .accepts").innerHTML = @acceptedMethods()

      if @location.type == 'problems'
        @div.querySelector(".problem-text .service-plans").innerHTML = @servicePlans()
        @div.querySelector(".problem-text .problem-reports").innerHTML = @problemReports()
        @div.querySelector(".problem-text .work-orders").innerHTML = @workOrders()
        @div.querySelector('.problem-text').classList.add('problem-text-padding')
      else
        @div.querySelector('.problem-text').classList.remove('problem-text-padding')
      @loadPhotos()

    workOrders: ->
      controller = @
      if @location.work_orders.length > 0
        ret = '<br /><strong>Work Orders</strong><br /><table class="problems-table"><tr><th class="blue-id">#ID</th><th>Due</th><th>Detail</th></tr>'
        $.each @location.work_orders, (index, work_order) ->
          ret = ret + '<tr><td><a href="' + work_order['url'] + '" title="' + work_order['id'] + '" target="_blank">' + work_order['id'] + '</a></td>'
          ret = ret + '<td>' + work_order['due_date'] + '</td>'
          ret = ret + '<td>' + controller.truncateString(work_order['description'], 15) + '</td></tr>'
        return ret + '</table>'
      else
        return ''

    truncateString: (str, num) ->
      return '' if str == undefined
      return str if str.length <= num

      return str.slice(0, num) + '...'

    problemReports: ->
      controller = @
      if @location.problem_reports.length > 0
        ret = '<br /><strong>Problem Reports</strong><br /><table class="problems-table"><tr><th class="blue-id">#ID</th><th>Prty</th><th>Due</th><th>Title</th></tr>'
        $.each @location.problem_reports, (index, problem_report) ->
          ret = ret + '<tr><td  class="blue-id"><a href="' + problem_report['url'] + '" title="' + problem_report['id'] + '" target="_blank">' + problem_report['id'] + '</a></td>'
          ret = ret + '<td><div class="priority ' + problem_report['priority_class'] + '"></div></td><td>' + problem_report['due_date']
          ret = ret + '</td><td>' + controller.truncateString(problem_report['title'], 15) + '</td></tr>'
        return ret + '</table>'
      else
        return ''

    servicePlans: ->
      if @location.service_plans.length == 0
        return '<div class="red">No plan</div>'
      else
        ret = '<br />'
        $.each @location.service_plans, (index, service_plan) ->
          ret = ret = '<div><strong>' + I18n.t("views.service_plans.#{service_plan['name']}") + '</strong> '
          if service_plan['expired'] == true
            ret = ret + '<span style="color: darkred">' + service_plan['expiration_date'] + '</span></div>'
          else
            ret = ret + '<span class="small">Ends in ' + service_plan['expired_months'] + 'months (' + service_plan['expiration_date'] + ')</span></div>'

      ret

    obtainStyle: ->
      style = { 'eLocker': 'locker', 'Group Parking': 'group', 'Bike Hangar': 'hangar', 'Vendor': 'vendor' }
      style[@location.location_friendly_type]

    totalNumberSpaces: ->
      return '' if @location.location_friendly_type == I18n.t('app.location.friendly_type.vendor')

      I18n.t 'modern.maps.list.additional_info.spaces.uniform', number: @location.num_spaces

    availableSpaces: ->
      return '' if !@location.online || @isOlderThan(@AVAILABILITY_THRESHOLD)
      I18n.t 'modern.maps.list.additional_info.free_spaces', number: @location.num_available_spaces

    oversizedSpaces: ->
      return I18n.t('modern.maps.list.additional_info.oversized_spaces.large_xlarge') if @location.num_large_spaces > 0 && @location.num_xlarge_spaces > 0
      return I18n.t('modern.maps.list.additional_info.oversized_spaces.large') if @location.num_large_spaces > 0 && @location.num_xlarge_spaces == 0
      return I18n.t('modern.maps.list.additional_info.oversized_spaces.xlarge') if @location.num_xlarge_spaces > 0 && @location.num_large_spaces == 0

      ''

    updatedAt: ->
      return '' if !@location.online || @isOlderThan(@AVAILABILITY_THRESHOLD)
      I18n.t('modern.maps.list.additional_info.last_update', time_diff: distance_of_time_in_words_to_now(@location.availability_updated_at))

    freeHours: ->
      return '' if @location.pricing_scheme == 'la_metro'
      return '' if @location.rental_free_hours.length == 0
      return I18n.t('modern.maps.list.additional_info.free_hours.simple', number: @location.rental_free_hours[0]) if @location.rental_free_hours.length == 1
      I18n.t('modern.maps.list.additional_info.free_hours.complex', from: @location.rental_free_hours.slice(0, -1).join(), to: @location.rental_free_hours[@location.rental_free_hours.length - 1])

    isOlderThan: (threshold) ->
      timeDiff = new Date().getTime() - new Date(@location.availability_updated_at * 1000).getTime()
      timeDiff > threshold * 3600 * 1000

    rentalPrice: ->
      switch @location.location_friendly_type
        when 'eLocker'
          if @location.pricing_scheme == 'la_metro'
            @location.pricing_tiers.tier1?.range_str ? @location.oversized_pricing_tiers.tier1?.range_str
          else
            @location.rental_cost_base
        when 'Group Parking'
          return @location.rental_cost_base if @location.is_access_hub
          @location.kiosk_cost_working
        else ''

    rentalDuration: ->
      return '' if @location.location_friendly_type == I18n.t('app.location.friendly_type.vendor')
      @location.max_rental_duration

    acceptedMethods: ->
      return '' if @location.location_friendly_type == I18n.t('app.location.friendly_type.vendor')
      accepted_methods = []
      accepted_methods.push I18n.t('modern.maps.list.additional_info.accepts.bikelink_card') if @location.can_use_bikelink_card
      accepted_methods.push I18n.t('modern.maps.list.additional_info.accepts.clipper_card') if @location.can_use_clipper_card
      accepted_methods.push I18n.t('modern.maps.list.additional_info.accepts.mobile_app') if @location.can_use_mobile_app
      I18n.t('modern.maps.list.additional_info.accepts.title') + accepted_methods.join(' - ')

    appendDivToOverlay: ->
      @getPanes().overlayImage.appendChild(@div)

    positionDiv: ->
      point = @getProjection().fromLatLngToDivPixel(@latlng)
      if point && @div
        if DeviceResolver.isMobile() || DeviceResolver.mobileWidth()
          @div.style.bottom = ($(".index-map-mobile").innerHeight() / 2) * (-1) + 'px'
          $('.map-bubble-close').click ->
            $('.mobile-tooltip.show-bubble').hide()
        else
          @div.style.left = point.x + 'px'
          @div.style.top = point.y - (@div.offsetHeight + @marker.div.offsetHeight + 10) + 'px'

    draw: ->
      if (!@div && !@shouldBeRemoved)
        @createDiv()
        @appendDivToOverlay()
      @positionDiv()

    getPosition: ->
      @latlng

    remove: =>
      @shouldBeRemoved = true
      return unless @div

      @div.parentNode.removeChild(@div)
      @div = null
      @setMap(null)

    # try to use prepared photos links
    loadPhotos: ->
      return @showPhotos(@location.photos) if new Date().getTime() - new Date(@location.updated_at).getTime() < @PHOTOS_EXPIRATION

      self = this
      $.ajax({
        url: '/maps/locations/' + @location.url_id + '/images/',
        type: 'GET',
        dataType: 'json',
        success: (data) ->
          self.showPhotos(data.images)
      })

    showPhotos: (images) ->
      return unless @div

      self = this
      @carousel = @div.querySelector('.carousel')
      carouselIndicators = @carousel.querySelector('.carousel-indicators')
      carouselInner = @carousel.querySelector('.carousel-inner')
      $.each images, (index, image) ->
        self.addToCarousel(carouselIndicators, carouselInner, image, index)
      $(@carousel).carousel('cycle')
      @increaseDivWidth(images.length)

    increaseDivWidth: (imagesLength, maxAttempts = 100) ->
      self = this
      return if maxAttempts == 0 || @div == null || imagesLength == 0
      callback = () -> self.increaseDivWidth(imagesLength, maxAttempts - 1)
      return setTimeout(callback, 25) if @div.offsetWidth == 0 || @carousel.offsetWidth == 0
      return if document.body.offsetWidth <= @div.offsetWidth

      @div.style.width = @div.offsetWidth + @carousel.offsetWidth + 'px'
      @div.style.left = @div.offsetLeft - @carousel.offsetWidth  + 'px'

    addToCarousel: (carouselIndicators, carouselInner, image, index) ->
      uid = 'carousel' + Math.random() * 100
      @carousel.id = uid
      @addCarouselIndicator(carouselIndicators, index)
      @addCarouselPhoto(carouselInner, image, index)

    addCarouselIndicator: (carouselIndicators, index) ->
      indicatorElement = document.createElement('li')
      indicatorElement.dataset = { target: @carousel.id, "slide-to": index }
      indicatorElement.className = 'active' if index == 0
      carouselIndicators.appendChild(indicatorElement)

    addCarouselPhoto: (carouselInner, image, index) ->
      styles = if index == 0 then 'carousel-item active' else 'carousel-item'
      photoElement = document.createElement('div')
      photoElement.className = styles
      imageElement = document.createElement('img')
      imageElement.src = image.thumbnail_url
      photoElement.appendChild(imageElement)
      carouselInner.appendChild(photoElement)

    updateCarouselControls: (carousel, uid) ->
      carousel.querySelector('.carousel-control-prev').href = '#' + uid
      carousel.querySelector('.carousel-control-next').href = '#' + uid

  return new CustomMapBubble(marker, location, map)

window.createCustomMapBubble = createCustomMapBubble

# https://gist.github.com/sulf/1157895/1ba230bbc21bddccca5c8d22866f8886ac4f907b
distance_of_time_in_words_to_now = (from_time) ->
  from_time = new Date(from_time * 1000).getTime()
  to_time = Date.now()

  distance_in_minutes = Math.round(Math.abs(to_time - from_time) / 60 / 1000)
  distance_in_seconds = Math.round(Math.abs(to_time - from_time) / 1000)

  if 0 <= distance_in_minutes && distance_in_minutes <= 1
    if 0 <= distance_in_seconds && distance_in_seconds <= 4
      "less than 5 seconds"
    else if 5 <= distance_in_seconds && distance_in_seconds <= 9
      "less than 10 seconds"
    else if 10 <= distance_in_seconds && distance_in_seconds <= 19
      "less than 20 seconds"
    else if 20 <= distance_in_seconds && distance_in_seconds <= 39
      "less than half a minute"
    else if 40 <= distance_in_seconds && distance_in_seconds <= 59
      "less than 1 minute"
    else "1 minute"

  else if 2 <= distance_in_minutes && distance_in_minutes <= 44
    "#{distance_in_minutes} minutes"
  else if 45 <= distance_in_minutes && distance_in_minutes <= 89
    "about 1 hour"
  else if 90 <= distance_in_minutes && distance_in_minutes <= 1439
    "about #{Math.round(distance_in_minutes/60.0)} hours"
  else if 1440 <= distance_in_minutes && distance_in_minutes <= 2529
    "1 day"
  else if 2530 <= distance_in_minutes && distance_in_minutes <= 43199
    "#{Math.round(distance_in_minutes/1440.0)} days"
  else if 43200 <= distance_in_minutes && distance_in_minutes <= 86399
    "about 1 month"
  else if 86400 <= distance_in_minutes && distance_in_minutes <= 525599
    "#{Math.round(distance_in_minutes/43200.0)} months"
  else
    distance_in_years = distance_in_minutes / 525600
    minute_offset_for_leap_year = (distance_in_years/4) * 1440
    remainder = ((distance_in_minutes - minute_offset_for_leap_year) % 525600)
    if remainder < 131400
      "about #{Math.round(distance_in_years)} years"
    else if remainder < 394200
      "over #{Math.round(distance_in_years)} years"
    else
      "almost #{Math.round(distance_in_years+1)} years"

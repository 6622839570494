import {Controller} from "@hotwired/stimulus"

// Connects to data-controller="components--select-switch-field"
export default class extends Controller {
    static targets = [ "field", "type" ]

    connect() {
        this.switchField();
    }

    switchField() {

        this.fieldTargets.forEach(element => {
            element.style.display = "none";
        });
        const selectedType = this.typeTarget.value;
        if (selectedType) {
            const fieldElement = document.getElementById(`${selectedType}_field`);
            if (fieldElement) fieldElement.style.display = "";
        }
    }
}

import {Controller} from "@hotwired/stimulus"

// Connects to data-controller="g7-access-hubs"
export default class extends Controller {
    static targets = ["submitButton", "form"]
    static values = {
        dialogSelector: String,
        openMode: {type: String, default: 'dialog'}
    }

    connect() {
        window.addEventListener('pageshow', () => {
            this._resetHiddenFields();
        });

        document.addEventListener('datagrid:selection-changed', this._handleDataGridSelectionChanged);

        document.addEventListener('turbo:load', () => {
            document.querySelector('.datagrid').addEventListener('datagrid:selection-changed', this._handleDataGridSelectionChanged);
        });

        if (!this.hasDialogSelectorValue) {
            console.error("No dialog selector specified!")
            return
        }

        this.dialogElements = document.querySelectorAll(this.dialogSelectorValue);

        this.element.addEventListener("click", e => {
            e.preventDefault();
            if (this.dialogElements.length > 0) {
                this.dialogElements.forEach(dialogElement => {
                    const hiddenField = dialogElement.querySelector('#access_hub_ids');
                    if (hiddenField) {
                        hiddenField.value = this.selectedRows;
                    }
                });
                this._open();
            } else {
                console.error(`Dialogs "${this.dialogSelectorValue}" not found!`);
            }
        });
    }

    _handleDataGridSelectionChanged = (event) => {
        this._resetHiddenFields();
        this.selectedRows = event.detail.selectedRows;
    };

    _resetHiddenFields() {
        document.querySelectorAll('input[type="hidden"][name="access_hub_ids[]"]').forEach((field) => field.remove());
        document.querySelectorAll('table.datagrid > tbody input[type="checkbox"][name="row_selector"]:checked').forEach((item) => this._addHiddenField(item.value));
    }

    _addHiddenField(id) {
        let field = document.createElement('input')
        field.setAttribute('type', 'hidden')
        field.setAttribute('name', 'access_hub_ids[]')
        field.setAttribute('value', id)
        this.element.appendChild(field)
    }

    _open() {
        this.dialogElements.forEach(dialogElement => {
            if (this.openModeValue === 'modal') {
                dialogElement.showModal();
            } else {
                dialogElement.show();
            }
        });
    }
}
import { Controller } from "@hotwired/stimulus"
import debounce from "lodash.debounce"

// Connects to data-controller="expenses"
export default class extends Controller {
  static targets = ['typeId']
  initialize() {
    this.updateTotal = debounce(this.updateTotal, 800).bind(this)
  }

  selectType(_event) {
    if (this.typeIdTarget.value) {
      this.element.requestSubmit()
    }
  }

  updateTotal(_event) {
    if (this.typeIdTarget.value) {
      this.element.requestSubmit()
    }
  }
}

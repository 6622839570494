import { Controller } from "@hotwired/stimulus"
import { Loader } from "@googlemaps/js-api-loader"

// Connects to data-controller="google-maps"
export default class extends Controller {
    static values = {
        apiKey: String,
        latitude: {type: String, default: "0.0"},
        longitude: {type: String, default: "0.0"},
        markerTitle: String,
        markerContent: String,
        draggable: Boolean,
    }

    connect() {
        const loader = new Loader({
            apiKey: this.apiKeyValue,
            version: "weekly",
        })

        loader.importLibrary('maps').then(({Map}) => {
            let mapOptions = {
                center: new google.maps.LatLng(this.latitudeValue, this.longitudeValue),
                mapTypeControl: true,
                zoom: 16,
            }
            this.map = new Map(this.element, mapOptions)
            this._createMarker()
        }).catch(e => {
            console.error(e)
        })
    }

    _createMarker() {
        let markerOptions = {
            draggable: this.draggableValue,
            map: this.map,
            position: new google.maps.LatLng(this.latitudeValue, this.longitudeValue),
            title: this.markerTitleValue,
        }
        let marker = new google.maps.Marker(markerOptions);
        if (this.draggableValue) {
            google.maps.event.addListener(marker, 'dragend', function (marker) {
                $('#location_longitude').val(marker.latLng.lng())
                $('#location_latitude').val(marker.latLng.lat())
            });
        }
        let infoWindow = new google.maps.InfoWindow({content: this.markerContentValue});
        google.maps.event.addListener(marker, 'click', function () {
            infoWindow.open(map, marker)
        });
    }
}

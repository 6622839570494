import { Controller } from "@hotwired/stimulus"
import debounce from "lodash.debounce"

// Connects to data-controller="labors"
export default class extends Controller {
  static targets = ['personId']

  initialize() {
    this.updateRate = debounce(this.updateRate, 800).bind(this)
  }

  selectPerson(_event) {
    if(this.personIdTarget.value) {
      this.element.requestSubmit()
    }
  }
  updateRate(_event) {
    if(this.personIdTarget.value) {
      this.element.requestSubmit()
    }
  }
}

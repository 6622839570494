import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="service-visits--work-orders-form"
export default class extends Controller {
  connect() {
    console.log('Connected to work-orders-form controller')
  }

  submit(_e) {
    this.element.requestSubmit()
  }
}
